import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import {utils} from "./utils";

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.config.globalProperties.utils = utils;
app.mount('#app')
