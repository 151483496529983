import {axios} from "./auth";

// 用户列表
export const lists = () => {
    return axios({
        url: '/user/lists',
        method: 'post',
        data: {}
    })
}
