/*
 * 接口统一集成模块
 */
import * as common from './common'
import * as project from './project'
import * as file from './file'
import * as user from './user'

// 默认全部导出
export const api = {
    common,
    project,
    file,
    user,
}
