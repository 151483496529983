import {axios} from "./auth";

// 登录
export const login = (username, password) => {
    return axios({
        url: '/login',
        method: 'post',
        data: {
            username: username,
            password: password
        }
    })
}

// 注册
export const register = (params) => {
    return axios({
        url: '/register',
        method: 'post',
        data: params
    })
}

// 获取验证码
export const captcha = (email) => {
    return axios({
        url: '/captcha',
        method: 'post',
        data: {
            email: email
        }
    })
}

export const axurePath = process.env.VUE_APP_AXURE
