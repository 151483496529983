import { createRouter,createWebHistory} from "vue-router";
const login = () => import("@/views/login")
const register = () => import("@/views/register")
const project = () => import("@/views/project/project")
const module = () => import("@/views/project/module")

const routes = [
    { path: "/", redirect: "/project" },
    {
        path: "/login",
        component: login
    },
    {
        path: "/register",
        component: register
    },
    {
        path: "/project",
        component: project
    },
    {
        path: "/project/module/:projectId",
        component: module,
    }

]
export const router = createRouter({
    history: createWebHistory(),
    routes: routes
})
