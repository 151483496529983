import {axios} from "./auth";

// 项目列表
export const lists = (params) => {
    return axios({
        url: '/project/lists',
        method: 'post',
        data: params
    })
}

// 添加项目
export const add = (params) => {
    return axios({
        url: '/project/add',
        method: 'post',
        data: params
    })
}

// 编辑项目
export const edit = (params) => {
    return axios({
        url: '/project/edit',
        method: 'post',
        data: params
    })
}

// 重置项目密码
export const resetPassword = projectId => {
    return axios({
        url: '/project/reset_password',
        method: 'post',
        data: {
            projectId: Number(projectId)
        }
    })
}

// 项目用户
export const projectUsers = projectId => {
    return axios({
        url: '/project/users',
        method: 'post',
        data: {
            projectId: Number(projectId)
        }
    })
}

// 添加项目用户
export const projectUserAdd = params => {
    return axios({
        url: '/project/user/add',
        method: 'post',
        data: params
    })
}

// 编辑项目用户
export const projectUserEdit = params => {
    return axios({
        url: '/project/user/edit',
        method: 'post',
        data: params
    })
}

// 项目列表
export const projectUserDelete = projectUserId => {
    return axios({
        url: '/project/user/delete',
        method: 'post',
        data: {
            projectUserId: Number(projectUserId)
        }
    })
}

// 模块列表
export const moduleLists = (params) => {
    return axios({
        url: '/project/module/lists',
        method: 'post',
        data: params
    })
}

// 添加模块
export const moduleAdd = (params) => {
    return axios({
        url: '/project/module/add',
        method: 'post',
        data: params
    })
}

// 编辑模块
export const moduleEdit = (params) => {
    return axios({
        url: '/project/module/edit',
        method: 'post',
        data: params
    })
}

// 重置密码
export const moduleResetPassword = projectModuleId => {
    return axios({
        url: '/project/module/reset_password',
        method: 'post',
        data: {
            projectModuleId: Number(projectModuleId)
        }
    })
}
